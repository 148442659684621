@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@media (max-width: 599px) {
  h2 {
    font-size: 1em;
  }
  h1 {
    font-size: 3em;
  }
  p {
    font-size: 1.3em;
    max-width: 500px;
  }
  .About img {
    width: 200px;
  }
  .Contact li {
    width: 48px;
  }
}

@media (min-width: 600px) {
  h1 {
    font-size: 5em;
  }
  h2 {
    font-size: 2em;
  }
  p {
    font-size: 1.5em;
    max-width: 700px;
  }
  .About img {
    width: 350px;
  }
  .Contact li {
    width: 96px;
  }
}

body {
  --color1: #F5F5F5;
  --color2: #FFFFFF;
  --color3: #1D1E21;
  --color4: #0f73ee;
  --color5: #585858;

}

html,
body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  background-color: var(--main-bg-color);
  color: var(--main-font-color);

  --main-bg-color: var(--color1);
  --second-bg-color: var(--color2);
  --highlight-font-color: var(--color3);
  --highlight-color: var(--color4);
  --main-font-color: var(--color5);
}

.App {
  height: 100vh;
  overflow: scroll;
}

h1,
h2,
h3,
h4{
  color: var(--highlight-font-color);
}

a {
  text-decoration: none;
}