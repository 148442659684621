@media (min-width: 1200px) {
  .ProjectCard {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .ProjectCard-Info {
    max-height: 800px;
    max-width: 500px;
    min-width: 350px
  }

  .ProjectCard h2 {
    font-size: 1.5em;
  }
}


@media (min-width: 1200px) and (max-width: 1599px) {
  .ProjectCard {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .ProjectCard-Info {
    max-height: 800px;
    max-width: 500px;
    min-width: 350px
  }

  .ProjectCard h2 {
    font-size: 1.5em;
  }

  .ProjectCard-Phone {
    display: none;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .ProjectCard {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .ProjectCard-Info {
    max-height: 800px;
    max-width: 500px;
    min-width: 350px
  }

  .ProjectCard h2 {
    font-size: 1.5em;
  }

  .ProjectCard-Web {
    display: none;
  }
}

@media (max-width: 899px) {
  .ProjectCard {
    display: flex;
    flex-direction: column;
    max-width: 550px;
  }

  .ProjectCard-Phone {
    display: none;
  }

  .ProjectCard-Web {
    display: none;
  }

  .ProjectCard h2 {
    font-size: 1.2em;
  }
}

.ProjectCard {
  align-items: center;
  background-color: var(--second-bg-color);
}

.ProjectCard-Info {
  padding: 10px;
  align-self: flex-start;
}

.ProjectCard-Image {
  overflow: hidden;
  margin: 0;
}


.ProjectCard-Image img{
  transition: 0.2s all ease-in-out;
  max-width: 100%;
  transform: scale(1.08);
}

.ProjectCard-Image:hover img {
  transform: scale(1.2);
  cursor: pointer;
}

.ProjectCard-Phone {
  height: 700px;
  margin: 10px;
  align-self: flex-start;
}

.ProjectCard-Phone img {
  max-height: 100%;
}

.ProjectCard-Web {
  height: 700px;
  margin: 10px;
  align-self: flex-start;
}

.ProjectCard-Web img {
  max-height: 100%;
}

.ProjectCard h2 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ProjectCard h2 svg {
  height: 20px;
  width: 20px;
}

.ProjectCard h2 a {
  font-size: 1em;
  letter-spacing: .2rem;
  text-transform: uppercase;
  color: var(--highlight-color);
  background-color: transparent;
  padding: 0;
}

.ProjectCard h2:hover a {
  background-color: transparent;
}

.ProjectCard h2:hover {
  cursor: pointer;
  gap: 12px;
  transition: gap 0.3s ease-in-out;
}

.ProjectCard p {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: 200;
}

.ProjectCard ul {
  list-style: none;
  margin: 0;
  padding: 20px 5px 7px 0;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: flex-end;
}

.ProjectCard a {
  color: var(--main-bg-color);
  background-color: var(--highlight-font-color);
  padding: 2px 8px;
  border-radius: 7px;
  font-size: 1.2em;
}

.ProjectCard li:last-child a {
  padding: 2px 15px;
}

.ProjectCard a:hover {
  cursor: pointer;
  background-color: var(--highlight-color);
}
