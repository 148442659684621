.About {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--second-bg-color);
  padding: 50px 0;
}

.About img {
  border-radius: 50%;
  max-width: 300px;
}

.About h1 {
  margin-bottom: 0;
}

.About p {
  text-align: center;
  margin: 0;
  padding: 20px;
}
