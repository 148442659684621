.Contact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Contact ul {
  width: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}



