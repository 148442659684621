@media (min-width: 1200px) {
  .Projects-Cont {
    padding: 40px 60px;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .Projects-Cont {
    padding: 20px 60px;
  }
}

@media (max-width: 899px) {
  .Projects-Cont {
    padding: 20px;
  }
}

.Projects {
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
}

.Projects h1 {
  margin: 0;
}

.Projects-Cont {
  max-width: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(280px, 1fr));
  align-items: center;
  justify-items: center;
  grid-gap: 40px;
}