
@media (min-width: 600px) {
  .Header .Cursor {
    margin: 0 0 -10px -15px;
  }

  .Header .Terminal {
    height: 150px;
    width: 150px;
  }

  .Header h2 {
    letter-spacing: .2rem;
  }
}

@media (min-width: 371px) and (max-width: 599px) {
  .Header .Cursor {
    margin: 0 0 -15px -10px;
    width: 20px;
  }

  .Header .Terminal {
    height: 100px;
    width: 100px;
  }

  .Header h2 {
    letter-spacing: .2rem;
  }
}

@media (max-width: 370px) {
  .Header .Cursor {
    margin: 0 0 -15px -10px;
    width: 20px;
  }

  .Header .Terminal {
    height: 100px;
    width: 100px;
  }

  .Header h2 {
    letter-spacing: .18rem;
  }
}


@keyframes prompt {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.Header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Header span {
  color: var(--highlight-color)
}

.Header .Cursor {
  animation: prompt 1.5s steps(1) infinite;
}

.Header .Terminal {
  margin: -10% 0 0 0;
}